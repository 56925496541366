import * as React from "react"
import { Link } from "gatsby"
import ShareActionBlock from "../ShareActionBlock/ShareActionBlock"
import "./styles.scss"

export default function Footer({ hideFooterNavigation }) {
    const path = typeof window !== "undefined" ? window.location.pathname : ''

    return (
        <div className={`footer ${path === '/map-preview' ? 'map' : ''}`}>
            <img
                src={"https://assets.xela.co/taste/taa/21c68590-a622-46c5-9c65-5c0a9e3f5868.svg"}
                className="logo taste"
                alt="Taste" />

            <ShareActionBlock />

            {!hideFooterNavigation && (
                <div className="accordion-tabs">
                    <Link to="/terms">Terms and Conditions</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/cookies">Cookie Policy</Link>
                    <Link to="/contacts">Contact Us</Link>
                </div>
            )}

            <div className="powered">
                <p>Powered by</p>
                <img
                    src={"https://assets.xela.co/taste/1476ef3d-a037-4c93-8464-c0007ad30980.svg"}
                    className="logo"
                    alt="Fabacus" />
            </div>
        </div>
    )
}
