import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Database from "../../_utils/Database"
import BurgerIcon from "../../../static/images/icons/burger-menu.svg"
import HeaderMenu from "../HeaderMenu/HeaderMenu"
import "./styles.scss"

const Header = ({ hideMenuItem }) => {
    const [isLogged, setIsLogged] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)

    useEffect(() => {
        if (Database.getUserToken()) {
            setIsLogged(true)
        }
    }, [isLogged])

    return (
        <>
            <nav className="header">
                <div className="header-content">
                    <Link to="/home">
                        <img
                            src={"https://assets.xela.co/taste/taa/21c68590-a622-46c5-9c65-5c0a9e3f5868.svg"}
                            className="logo"
                            alt="Taste"
                        />
                    </Link>

                    <div className="menu">
                        <button
                            className="btn-menu"
                            onClick={() => setOpenMenu(true)}>
                            <img
                                src={BurgerIcon}
                                alt="menu-icon"
                            />
                        </button>
                    </div>

                    <HeaderMenu openMenu={openMenu} setOpenMenu={setOpenMenu} hideMenuItem={hideMenuItem} />
                </div>
            </nav>

            {/* <UserMenu isLogged={isLogged} /> */}
        </>
    )
}

export default Header
