import * as React from "react"
import "./styles.scss"

export default function ShareActionBlock() {
    return (
        <div className="share-with-friends-block">
            <div className="share-block-action">
                <div className="block-content">
                    <p>Share with Friends</p>
                </div>
                <div className="share-item-list">
                    <div className="share-item">
                        <a target="_blank" rel="noreferrer"
                            href="https://www.facebook.com/TasteLondon">
                            <img src="/images/social-icons/facebook.svg" alt="" />
                        </a>
                    </div>

                    <div className="share-item">
                        <a target="_blank" rel="noreferrer"
                            href="https://x.com/tasteoflondon">
                            <img src="/images/social-icons/twitter.svg" alt="" />
                        </a>
                    </div>

                    <div className="share-item">
                        <a target="_blank" rel="noreferrer"
                            href="https://www.tiktok.com/@tasteoflondonuk">
                            <img src="/images/social-icons/tiktok.svg" alt="" />
                        </a>
                    </div>

                    <div className="share-item">
                        <a target="_blank" rel="noreferrer"
                            href="https://www.instagram.com/tasteoflondon">
                            <img src="/images/social-icons/instagram.svg" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
