import React from "react"
import Qr from "../../_utils/Qr"
import CookiesMessage from "../CookiesMessage/CookiesMessage"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import "../../_styles/index.scss"
import "./styles.scss"

const Layout = (props) => {
    const { title, children, hideCookies, hideFooterNavigation } = props

    const isGameParams = Qr.getGameParams()

    return (
        <>
            <title>
                {title ? `${title} | Taste of London` : `Taste of London`}
            </title>

            <main className="container">
                <Header hideMenuItem={!isGameParams} />

                <div id="content">
                    {children}
                </div>

                {!hideCookies && (
                    <CookiesMessage />
                )}

                <Footer hideFooterNavigation={hideFooterNavigation} />
            </main>
        </>
    )
}

export default Layout
