import React from "react"
import "./styles.scss"

const MainTitle = (props) => {
    const { children, secondary, className } = props
    
    return (
        <>
            {secondary ? (
                <h2 className={`secondary-title ${!!className ? className : ''}`}>
                    {children}
                </h2>
            ) : (
                <h1 className={`main-title ${!!className ? className : ''}`}>
                    {children}
                </h1>
            )}
        </>
    )
}

export default MainTitle
