export default class Database {
  static getQrParams = () => {
    return this._getFromLocalStorage("qrParams");
  };

  static getGameParams = () => {
    return this._getFromLocalStorage("gamePayload");
  };

  static getGameChoices = () => {
    return this._getFromLocalStorage("gameChoices");
  };

  static setGameParams = (value) => {
    this._saveToLocalStorage("gamePayload", value);
  };

  static setGameChoices = (value) => {
    this._saveToLocalStorage("gameChoices", value);
  };

  static setQrParams = (value) => {
    this._saveToLocalStorage("qrParams", value);
  };

  static removeQrParams = () => {
    this._removeFromLocalStorage("qrParams");
  };

  static removeGameParams = () => {
    this._removeFromLocalStorage("gamePayload");
    this._removeFromLocalStorage("gameChoices");
  };

  static getUserToken = () => {
    return this._getFromLocalStorage("userToken");
  };

  static setUserToken = (value) => {
    this._saveToLocalStorage("userToken", value);
  };

  static removeUserToken = () => {
    this._removeFromLocalStorage("userToken");
  };

  static getCookieAccepted = () => {
    return this._getFromLocalStorage("cookieAccepted");
  };

  static setCookieAccepted = (value) => {
    this._saveToLocalStorage("cookieAccepted", value);
  };

  static removeCookieAccepted = () => {
    this._removeFromLocalStorage("cookieAccepted");
  };

  static getRewardsMessage = () => {
    return this._getFromLocalStorage("rewardsMessage");
  };

  static setRewardsMessage = (value) => {
    this._saveToLocalStorage("rewardsMessage", value);
  };

  static removeRewardsMessage = () => {
    this._removeFromLocalStorage("rewardsMessage");
  };

  static getRewardsList = () => {
    return this._getFromLocalStorage("rewardsList");
  };

  static setRewardsList = (value) => {
    this._saveToLocalStorage("rewardsList", value);
  };

  static removeRewardsList = () => {
    this._removeFromLocalStorage("rewardsList");
  };

  static _getFromLocalStorage = (key) => {
    if (typeof localStorage !== undefined) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        //
      }
    }

    return null;
  };

  static _saveToLocalStorage = (key, value) => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  static _removeFromLocalStorage = (key) => {
    if (typeof localStorage !== "undefined") {
      localStorage.removeItem(key);
    }
  };
}
